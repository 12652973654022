import React, { useState } from "react";
import logo from "../../assets/lkp-logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../../redux/slices/errorMessage";
import { setSuccessMessage } from "../../redux/slices/successMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./PasswordResetRequest.css";

function PasswordResetRequest() {
  const production = useSelector((state) => state.production.production);
  const [capVal, setCapVal] = useState(null);
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const testingURL = "http://127.0.0.1:5000/reset-password";
  const productionURL = "https://lkp-api.vercel.app/reset-password";

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(production ? productionURL : testingURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: values.email,
            token,
          }),
        });

        if (!response.ok) {
          dispatch(setErrorMessage("Invalid email address"));
        } else {
          dispatch(setSuccessMessage("Password reset link successfully sent"));
          navigate("/");
        }
      } catch (error) {
        console.error("Error sending password reset link:", error.message);
        dispatch(setErrorMessage("Error sending password reset link"));
        navigate("/reset-password"); // Redirect back to reset password request page on error
      }
    },
  });

  return (
    <div className="PasswordReset">
      <div className="password-reset-wrapper">
        <Link to="/">
          <img src={logo} width={150} alt="logo" />
        </Link>
        <form onSubmit={formik.handleSubmit}>
          <h3>Reset Your Password</h3>
          <p>
            Type in your email and we'll send you a link to reset your password
          </p>
          <input
            type="text"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            placeholder="Email"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error-message">{formik.errors.email}</div>
          ) : null}
          <ReCAPTCHA
            sitekey="6Lc3DQ0qAAAAAGqF6SVufDCzjO5NcryMUpqUTSKQ"
            onChange={(val) => setCapVal(val)}
          />
          <button disabled={!capVal} type="submit">
            SUBMIT
          </button>
        </form>
      </div>
    </div>
  );
}

export default PasswordResetRequest;
