import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  production: true,
};

export const productionSlice = createSlice({
  name: "production",
  initialState,
  reducers: {
    setProduction: (state, action) => {
      state.production = action.payload;
    },
  },
});

export const { setProduction } = productionSlice.actions;

export default productionSlice.reducer;
