import React from "react";
import "./RefundPolicy.css";

function RefundPolicy() {
  return (
    <div className="RefundPolicy">
      <div className="refund-policy-wrapper">
        <h3>Refund Policy</h3>
        <h3>1. Eligibility for Refunds</h3>
        <p>eBooks:</p>
        <ul styles={{ paddingTop: "1rem" }}>
          <li>
            Customers are eligible for refunds within 7 days of purchase under
            the following conditions:
          </li>
          <ul>
            <li>
              The eBook must not have been downloaded or accessed beyond a
              sample read, as evidenced by the platform's access logs.
            </li>
            <li>
              Refund requests must be made within the stipulated 7-day period
              from the date of purchase.
            </li>
            <li>
              To initiate a refund, customers must provide proof of purchase.
            </li>
            <li>
              Refunds will be processed using the original payment method
              wherever possible.
            </li>
            <li>
              In cases where the eBook has been accessed beyond a sample read,
              refunds may not be granted unless the eBook is deemed defective or
              fails to meet promised specifications.
            </li>
          </ul>
        </ul>
        <h3>2. Refund Request Process</h3>
        <p>
          To initiate a refund, customers are required to contact our platform’s
          customer support team. They should provide the order number along with
          a brief explanation of the reason for the refund request. Our customer
          support representatives are available to assist customers through the
          refund process and ensure that requests are handled promptly and
          fairly. We strive to maintain transparency and provide clear
          communication throughout the refund process.
        </p>
        <h3>3. Approval Process</h3>
        <p>
          Every refund request submitted by customers undergoes thorough review
          by our dedicated support team. The team carefully evaluates each
          request to ensure compliance with our refund policy. We reserve the
          right to approve or deny refund requests based on the criteria
          outlined in our policy, which includes considerations such as
          eligibility criteria and the reason provided for the refund. Customers
          can expect our support team to communicate promptly regarding the
          status of their refund request and any necessary steps to facilitate
          the process effectively.
        </p>
        <h3>4. Refund Method</h3>
        <p>
          Upon approval, refunds will be credited back to the initial payment
          method used for the transaction, a process typically completed within
          5-7 business days. This systematic approach ensures a consistent and
          transparent refund procedure, thereby reducing potential
          misunderstandings. By adhering to this practice, we uphold financial
          security measures and align with established industry norms. This
          commitment to returning funds to the original payment source
          underscores our dedication to maintaining reliable and straightforward
          refund processes.
        </p>
        <h3>5. Non-eligible Items</h3>
        <p>
          Items such as bundled eBooks or promotional items are generally
          excluded from our refund policy, unless explicitly specified otherwise
          in the terms of the promotion. This policy ensures clarity and
          fairness regarding refund eligibility, protecting both the company and
          customers from potential misunderstandings. Customers are encouraged
          to review promotion terms carefully to understand refund eligibility
          for specific items before making a purchase.
        </p>
        <h3>6. Access Limitations</h3>
        <p>
          Once an eBook has been accessed beyond a sample read or extensively
          used, it becomes ineligible for refunds. This policy is in place to
          ensure fairness and to protect against misuse of our refund system.
          It’s important for customers to be aware of this policy when
          purchasing eBooks, as it underscores our commitment to providing
          quality service and protecting intellectual property rights. We
          encourage customers to review samples thoroughly before making a
          purchase decision to ensure satisfaction.
        </p>
        <h3>7. Policy Updates</h3>
        <p>
          This refund policy is subject to updates at our discretion. We are
          committed to informing customers about any revisions through our
          platform’s communication channels. This ensures transparency and keeps
          our clientele informed of any changes that may affect their
          transactions. It is advisable for customers to stay updated with our
          communications to remain informed about any modifications to this
          refund policy.
        </p>
        <h3>8. Customer Responsibility</h3>
        <p>
          Customers are responsible for ensuring that their device or software
          is compatible with the eBook format before making a purchase. This
          policy emphasizes the importance of verifying compatibility to avoid
          issues post-purchase. By taking this responsibility, customers can
          ensure a seamless experience and minimize potential compatibility
          issues. We encourage customers to review device and software
          specifications or consult with our support team if they have any
          concerns regarding compatibility.
        </p>
        <h3>9. Fraudulent Activity</h3>
        <p>
          Refunds will not be provided in instances where fraudulent activity or
          misuse of the refund process is suspected. This policy is in place to
          maintain integrity and fairness in our transactions. We take such
          matters seriously to protect both our business and genuine customers.
          Customers are encouraged to use our refund process responsibly and
          adhere to our terms and conditions to ensure a positive experience for
          all.
        </p>
        <h3>10. Exceptions</h3>
        <p>
          In exceptional circumstances, such as technical errors or misleading
          product descriptions, refunds may be granted at the discretion of our
          support team, even if they fall outside the standard policy. This
          policy ensures that customers are fairly treated and issues are
          promptly addressed. We encourage customers to reach out to our support
          team promptly in such cases so that we can assess the situation and
          provide appropriate assistance. By maintaining flexibility in our
          refund policy, we aim to uphold trust and transparency in our business
          practices.
        </p>
      </div>
    </div>
  );
}

export default RefundPolicy;
