import React from "react";
import Carousel from "react-multi-carousel";
import { useSelector, useDispatch } from "react-redux";
import { setProductID } from "../../redux/slices/productID";
import "react-multi-carousel/lib/styles.css";
import "./Library.css";

function Library() {
  const library = useSelector((state) => state.library.library);
  const dispatch = useDispatch();

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 8 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 6 },
    tablet: { breakpoint: { max: 1024, min: 750 }, items: 4 },
    mobile: { breakpoint: { max: 750, min: 0 }, items: 2 },
  };

  const handleBookDetails = (productId) => {
    dispatch(setProductID(productId));
  };

  return (
    <div className="Library">
      <h3>Library</h3>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={false}
        keyBoardControl={true}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {library?.length > 0 ? (
          library?.map((item) => (
            <div
              key={item.id}
              className="library-card"
              onClick={() => handleBookDetails(item.product_id)}
            >
              <img src={item.product_images} alt={item.name} />
            </div>
          ))
        ) : (
          <div style={{ paddingLeft: "1rem" }}>No items in the library.</div>
        )}
      </Carousel>
    </div>
  );
}

export default Library;
