import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setLibrary } from "../../redux/slices/library";
import { setCartItems } from "../../redux/slices/cartItems";

const Success = () => {
  const library = useSelector((state) => state.library.library);
  const user = useSelector((state) => state.user.user);
  const cartItems = useSelector((state) => state.cartItems.cartItems);
  const production = useSelector((state) => state.production.production);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const testingURL = "http://127.0.0.1:5000";
  const productionURL = "https://lkp-api.vercel.app";

  const getAPIUrl = (endpoint) =>
    production ? `${productionURL}${endpoint}` : `${testingURL}${endpoint}`;

  useEffect(() => {
    const handleProductLibrary = async () => {
      try {
        const response = await fetch(getAPIUrl("/library"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.access_token}`,
          },
          body: JSON.stringify({
            cartItems: cartItems.map((item) => ({
              product_id: item.id,
              product_name: item.name,
              product_images: item.images[0] || "",
              product_description: item.description || "",
              product_author: item.metadata?.author || "",
              product_genre: item.metadata?.genre || "",
              product_logo: item.metadata?.book_logo || "",
              product_cover: item.metadata?.book_cover || "",
              user_id: user.user.id,
            })),
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to add items to the Product Library");
        }
        const responseData = await response.json();
        const updatedLibraries = [...library, ...responseData];

        localStorage.setItem("library", JSON.stringify(updatedLibraries));
        dispatch(setLibrary(updatedLibraries));

        await handleProductMetrics(cartItems);

        localStorage.removeItem("cartItems");
        dispatch(setCartItems([]));
        navigate("/");
      } catch (error) {
        console.error("Error adding items to the Product Library:", error);
        // Optionally show error to the user
      }
    };

    const handleProductMetrics = async (cartItems) => {
      try {
        const response = await fetch(getAPIUrl("/metrics"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cartItems: cartItems.map((item) => ({
              product_id: item.id,
              product_name: item.name,
              product_author: item.metadata?.author || "",
              product_currency_type: item.default_price?.currency || "USD",
              product_net_amount: item.default_price?.unit_amount || 0,
              owner_id: parseInt(item.metadata?.owner_id, 10) || 0,
            })),
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to update product metrics");
        }
      } catch (error) {
        console.error("Error updating product metrics:", error);
        // Optionally show error to the user
      }
    };

    handleProductLibrary();
  }, [cartItems, user, dispatch, navigate]);

  return <div>Loading...</div>; // Consider adding a loading indicator or message
};

export default Success;
