import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMetrics } from "../../redux/slices/metrics";

function MetricsData() {
  const dispatch = useDispatch();
  const production = useSelector((state) => state.production.production);

  const testingURL = "http://127.0.0.1:5000/metrics";
  const productionURL = "https://lkp-api.vercel.app/metrics";

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await fetch(production ? productionURL : testingURL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        dispatch(setMetrics(data));
      } catch (error) {
        console.error("Error fetching metrics:", error.message);
      }
    };

    fetchMetrics();
  }, [dispatch, production]);

  return null; // This component does not render anything
}

export default MetricsData;
