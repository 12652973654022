import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Hero.css";

function Hero({ featuredProducts, addToCart }) {
  const library = useSelector((state) => state.library.library);
  const [bookCoverIndex, setBookCoverIndex] = useState(0);
  const navigate = useNavigate();

  const isBought = library?.some(
    (item) => item.product_id === featuredProducts[bookCoverIndex].id
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setBookCoverIndex((prevIndex) =>
        prevIndex < featuredProducts.length - 1 ? prevIndex + 1 : 0
      );
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [featuredProducts.length]);

  const handlePurchasedBook = () => {
    navigate(`/product`);
  };

  return (
    <div className="Hero">
      {featuredProducts.length > 0 &&
        featuredProducts.map((product, index) => (
          <div
            className="book-cover"
            style={{
              opacity: bookCoverIndex === index ? 1 : 0,
              zIndex: bookCoverIndex === index ? 1 : 0,
              backgroundImage: `url(${product.metadata.book_cover})`,
            }}
            key={product.id}
          >
            <img src={product.metadata.book_logo} alt="Book Logo" />
            <p>{product.description}</p>
            <div className="hero-btn-wrapper">
              {isBought ? (
                <button onClick={handlePurchasedBook}>READ NOW</button>
              ) : product.metadata?.iframe_src ? (
                <button
                  onClick={() => addToCart(featuredProducts[bookCoverIndex])}
                >
                  ADD TO CART
                </button>
              ) : (
                <button disabled>COMMING SOON</button>
              )}
              <button>DETAILS</button>
            </div>
          </div>
        ))}
    </div>
  );
}

export default Hero;
