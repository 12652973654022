import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoCloseOutline } from "react-icons/io5";
import { setProductGenre } from "../../redux/slices/productGenre";
import { setProductID } from "../../redux/slices/productID";
import "./ProductByGenre.css";

function ProductByGenre() {
  const products = useSelector((state) => state.products.products);
  const productGenre = useSelector((state) => state.productGenre.productGenre);
  const dispatch = useDispatch();

  const genreProducts = products.filter((item) => {
    const genres = item.metadata.genre.split(",").map((genre) => genre.trim());
    return genres.includes(productGenre);
  });

  const closeGenreCat = () => {
    dispatch(setProductGenre(""));
  };

  const handleBookDetails = (productId) => {
    dispatch(setProductID(productId));
    dispatch(setProductGenre(""));
  };

  return (
    <div className="ProductByGenre">
      <div className="product-by-genre-wrapper">
        <div className="genre-title">
          <h3>{productGenre}</h3>
          <button onClick={closeGenreCat}>
            <IoCloseOutline size={36} color="white" />
          </button>
        </div>
        <div className="genre-card-wrapper">
          {genreProducts.map((item) => (
            <div
              onClick={() => handleBookDetails(item.id)}
              className="genre-card"
            >
              <img src={item.images[0]} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductByGenre;
