import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { IoCloseOutline } from "react-icons/io5";
import "./PurchasedBook.css";

function PurchasedBook() {
  const productID = useSelector((state) => state.productID.productID);
  const production = useSelector((state) => state.production.production);
  const testingURL = `http://127.0.0.1:5000/products/${productID}`;
  const productionURL = `https://lkp-api.vercel.app/products/${productID}`;
  const [productDataByID, setProductDataByID] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(production ? productionURL : testingURL);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProductDataByID(data);
      } catch (error) {
        console.error("Error fetching products:", error.message);
      }
    };

    fetchProducts();
  }, [productID]);

  const handleCloseBook = () => {
    localStorage.removeItem("productid"); // Remove product ID from localStorage
    window.location.href = "/"; // Redirect to home page
  };

  return (
    <div className="PurchasedBook">
      <button className="close-book" onClick={handleCloseBook}>
        <IoCloseOutline size={38} color="white" />
      </button>
      <iframe
        src={productDataByID.metadata?.iframe_src}
        width="100%"
        height="100%" // Optional: Make iframe take full height of container
        style={{ border: "none" }} // Optional: Remove iframe border
      />
    </div>
  );
}

export default PurchasedBook;
