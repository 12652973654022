import React from "react";
import "./DashboardOverview.css"; // Ensure you have the correct path to your CSS file

function DashboardOverview() {
  return (
    <div className="DashboardOverview">
      Overview under construction. Please check back soon
    </div>
  );
}

export default DashboardOverview;
