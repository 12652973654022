import React from "react";
import "./TermsAndConditions.css";

function TermsAndConditions() {
  return (
    <div className="TermsAndConditions">
      <div className="terms-and-conditions-wrapper">
        <h3>Terms and Conditions for Purchase and Access to Ebooks</h3>
        <h3>1. Acceptance of Terms</h3>
        <p>
          By accessing or using the website, users agree to abide by these terms
          and conditions, along with any applicable policies. Users acknowledge
          their responsibility to review and adhere to the rules and guidelines
          established by the platform. Ongoing use of the website signifies
          acceptance of these terms, forming a legal agreement between the user
          and the platform's operator. This agreement includes content usage,
          intellectual property rights, privacy practices, and acceptable
          conduct.
        </p>
        <h3>2. Ebook Purchases and Licensing</h3>
        <p>
          Users can purchase ebooks through the platform, subject to pricing,
          availability, and terms that may change without notice. Upon purchase,
          users receive a non-exclusive, non-transferable license to access and
          read the ebook for personal, non-commercial use. Distribution,
          reproduction, or alteration of the ebook content is prohibited without
          explicit authorization. The platform retains all rights to the ebooks,
          including intellectual property rights. Users must ensure device
          compatibility. Unauthorized use may result in account suspension or
          legal action. By purchasing, users agree to these licensing terms and
          platform policies.
        </p>
        <h3>3. Device Compatibility</h3>
        <p>
          Ebooks are accessible on devices and apps compatible with the
          platform’s specifications. Users must ensure their devices meet these
          requirements. The platform specifies supported operating systems,
          device types, or app versions necessary for access. Users should check
          compatibility details before purchase to avoid issues. The platform
          does not guarantee functionality on unsupported devices or apps. Any
          changes to compatibility requirements will be communicated through
          official channels.
        </p>
        <h3>4. Content Guidelines</h3>
        <p>
          Users must refrain from uploading, sharing, or accessing illegal or
          infringing content, including content that violates intellectual
          property rights, is defamatory, obscene, or unlawful. The platform
          reserves the right to remove content that breaches these guidelines.
          Users are responsible for their content and must comply with all
          applicable laws. Violations may result in content removal, account
          suspension, or legal consequences. Users agree to adhere to these
          guidelines and accept the platform’s authority to enforce them
        </p>
        <h3>5. Privacy Policy</h3>
        <p>
          The privacy policy governs the collection, use, and protection of
          personal information when purchasing ebooks. Necessary details like
          name, email, billing address, and payment information are collected
          for transaction processing and delivery. This information is used to
          communicate order updates, personalize experiences, and improve
          services. Data security is prioritized with industry-standard
          measures. Information is not disclosed to third parties except for
          transaction fulfillment or legal compliance. Users can access,
          correct, or delete their personal data and manage communication
          preferences. Changes to the privacy policy will be posted on the
          website, and continued use signifies acceptance. For details, refer to
          the complete privacy policy on the website or contact us.
        </p>
        <h3>6. Intellectual Property</h3>
        <p>
          All content on the website, including ebooks, is protected by
          intellectual property laws. Users are prohibited from reproducing,
          distributing, modifying, or creating derivative works without explicit
          permission from rights holders. Unauthorized use may result in legal
          action or account suspension. The platform enforces these protections
          to safeguard content creators' rights and platform integrity. For
          permissions, contact the platform using the provided information.
        </p>
        <h3>7. User Conduct</h3>
        <p>
          Users must adhere to community standards and conduct themselves to
          uphold the platform's operational integrity. Prohibited activities
          include hacking, data mining, unauthorized access, and other
          violations of laws or regulations. Users must respect others' privacy
          and rights and avoid compromising the platform's security or user
          experience. Violations may result in account suspension or legal
          action. By using the platform, users agree to these conduct standards
          and contribute to a positive and secure environment.
        </p>
        <h3>8. Liability and Disclaimers</h3>
        <p>
          The platform offers ebooks and services on an “as is” and “as
          available” basis, without warranties. While accuracy, availability,
          and quality are prioritized, the platform does not warrant error-free
          or uninterrupted content. Use of the platform is at users' own risk.
          The platform disclaims liability for damages, losses, or expenses
          related to the use or inability to use services, including direct,
          indirect, incidental, consequential, or punitive damages. Users accept
          these liability limitations as required by applicable laws and
          regulations.
        </p>
        <h3>9. Termination</h3>
        <p>
          The platform may terminate or suspend access to accounts or services
          at its discretion, especially for violations of terms or policies.
          Violations include prohibited activities, intellectual property
          breaches, or operational disruptions. Notice may or may not be
          provided based on the violation's severity. The platform is not liable
          for consequences of account termination or suspension, including loss
          of data or access. These measures maintain platform integrity,
          security, and lawful operation for all users.
        </p>
        <h3>10. Governing Law</h3>
        <p>
          These terms are governed by the laws of [jurisdiction], excluding
          conflict of law principles. Disputes arising from these terms or
          platform use will be settled through arbitration by [arbitration
          organization] per its rules, with the arbitration held in [venue],
          [jurisdiction]. Parties consent to the exclusive jurisdiction of
          courts in [venue], [jurisdiction] for non-arbitration actions.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
