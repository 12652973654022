import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toggleCart: false,
};

export const toggleCartSlice = createSlice({
  name: "toggleCart",
  initialState,
  reducers: {
    setToggleCart: (state, action) => {
      state.toggleCart = action.payload;
    },
  },
});

export const { setToggleCart } = toggleCartSlice.actions;

export default toggleCartSlice.reducer;
