import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { setUser } from "./redux/slices/user";
import { setLibrary } from "./redux/slices/library";
import { setCartItems } from "./redux/slices/cartItems";
import { setProductID } from "./redux/slices/productID";
import { setSuccessMessage } from "./redux/slices/successMessage";
import { setErrorMessage } from "./redux/slices/errorMessage";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./components/Navbar/Navbar";
import ProductData from "./utilis/api/productData";
import MetricsData from "./utilis/api/metricsData";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import HomePage from "./pages/HomePage/HomePage";
import PurchasedBook from "./pages/PurchasedBook/PurchasedBook";
import Account from "./pages/Account/Account";
import Dashboard from "./pages/Dashboard/Dashboard";
import DashboardOverview from "./pages/DashboardOverview/DashboardOverview";
import Analytics from "./pages/Analytics/Analytics";
import Users from "./pages/Users/Users";
import Cart from "./components/Cart/Cart";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import Success from "./pages/Success/Success";
import Footer from "./components/Footer/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy/RefundPolicy";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PasswordResetRequest from "./pages/PasswordResetRequest/PasswordResetRequest";
import PasswordReset from "./pages/PasswordReset/PasswordReset";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

function App() {
  const isLoading = useSelector((state) => state.isLoading.isLoading);
  const productID = useSelector((state) => state.productID.productID);
  const cartItems = useSelector((state) => state.cartItems.cartItems);
  const successMessage = useSelector(
    (state) => state.successMessage.successMessage
  );
  const errorMessage = useSelector((state) => state.errorMessage.errorMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage);
      dispatch(setSuccessMessage(""));
    }
  }, [cartItems, successMessage]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
      dispatch(setErrorMessage(""));
    }
  }, [cartItems, errorMessage]);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedCartItems = localStorage.getItem("cartItems");
    const storedUser = localStorage.getItem("user");
    const storedLibrary = localStorage.getItem("library");
    const storedProductID = localStorage.getItem("productid");

    if (storedCartItems && storedCartItems !== "undefined") {
      try {
        const parsedCartItems = JSON.parse(storedCartItems);
        dispatch(setCartItems(parsedCartItems));
      } catch (error) {
        console.error("Error parsing cart items from localStorage:", error);
      }
    }

    if (storedUser && storedToken && storedUser !== "undefined") {
      try {
        const parsedUser = JSON.parse(storedUser);
        dispatch(setUser(parsedUser));
      } catch (error) {
        console.error("Error parsing user from localStorage:", error);
      }
    }

    if (storedLibrary && storedLibrary !== "undefined") {
      try {
        const parsedLibrary = JSON.parse(storedLibrary);
        dispatch(setLibrary(parsedLibrary));
      } catch (error) {
        console.error("Error parsing library from localStorage:", error);
      }
    }

    if (storedProductID && storedProductID !== "undefined") {
      try {
        const parsedProductID = JSON.parse(storedProductID);
        dispatch(setProductID(parsedProductID));
      } catch (error) {
        console.error("Error parsing product ID from localStorage:", error);
      }
    }
  }, [dispatch]);

  const fetchedData = () => {
    return (
      <>
        <ProductData />
        <MetricsData />
      </>
    );
  };

  return (
    <div className="App">
      {fetchedData()}
      {isLoading ? (
        <LoadingPage />
      ) : (
        <>
          <Router>
            <Navbar />
            <Cart />
            <ToastContainer autoClose={5000} />
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route
                exact
                path={`/product/${productID}`}
                element={<PurchasedBook />}
              />
              <Route exact path="/account" element={<Account />} />
              <Route exact path="/success" element={<Success />} />
              <Route exact path="/dashboard" element={<Dashboard />}>
                <Route index element={<DashboardOverview />} />
                <Route
                  exact
                  path="/dashboard/analytics"
                  element={<Analytics fetchedData={fetchedData} />}
                />
                <Route exact path="/dashboard/users" element={<Users />} />
              </Route>
              <Route exact path="/signin" element={<SignIn />} />
              <Route exact path="/signup" element={<SignUp />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route exact path="/refund-policy" element={<RefundPolicy />} />
              <Route
                exact
                path="terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route
                exact
                path="/reset-password"
                element={<PasswordResetRequest />}
              />
              <Route
                path="/reset-password/:token"
                element={<PasswordReset />}
              />
            </Routes>
            <Footer />
          </Router>
        </>
      )}
    </div>
  );
}

export default App;
