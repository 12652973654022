import React from "react";
import logo from "../../assets/lkp-logo.png";
import { useSelector, useDispatch } from "react-redux";
import { setToggleCart } from "../../redux/slices/toggleCart";
import { PiShoppingCart } from "react-icons/pi";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const cartItems = useSelector((state) => state.cartItems.cartItems);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const handleCartMenu = () => {
    dispatch(setToggleCart(true)); // Close the cart
  };

  return (
    <div className="Navbar">
      <div className="navbar-left">
        <Link to="/">
          <img src={logo} width={150} />
        </Link>
      </div>
      <div className="navbar-right">
        {user ? (
          <Link to="/account">ACCOUNT</Link>
        ) : (
          <Link to="/signin">SIGN IN</Link>
        )}
        <button onClick={handleCartMenu}>
          <PiShoppingCart size={18} /> <span>{cartItems.length}</span>
        </button>
      </div>
    </div>
  );
}

export default Navbar;
