import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setProductID } from "../../redux/slices/productID";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./NewReleases.css";

function NewReleases() {
  const products = useSelector((state) => state.products.products);
  const newReleases = products.slice(0, 21);
  const dispatch = useDispatch();

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 8 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 6 },
    tablet: { breakpoint: { max: 1024, min: 750 }, items: 4 },
    mobile: { breakpoint: { max: 750, min: 0 }, items: 2 },
  };

  const handleBookDetails = (productId) => {
    dispatch(setProductID(productId));
  };

  return (
    <div className="NewReleases">
      <h3>New Releases</h3>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={false}
        keyBoardControl={true}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {newReleases.map((product) => (
          <div
            className="new-releases-card"
            key={product.id}
            onClick={() => handleBookDetails(product.id)}
          >
            <img src={product.images[0]} alt="Book Cover" />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default NewReleases;
