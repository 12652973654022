import React from "react";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import "./Dashboard.css";

function Dashboard() {
  return (
    <div className="Dashboard">
      <DashboardNavbar />
      <div className="dashboard-wrapper">
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
