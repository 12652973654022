import React from "react";
import Carousel from "react-multi-carousel";
import { useSelector, useDispatch } from "react-redux";
import { setProductID } from "../../redux/slices/productID";
import { setProductGenre } from "../../redux/slices/productGenre";
import "react-multi-carousel/lib/styles.css";
import "./SciFi.css";

function SciFi() {
  const products = useSelector((state) => state.products.products);
  const sciFiGenreProducts = products.filter((item) => {
    // Assuming genre is a comma-separated string, check if "Sci-Fi" is one of the genres
    const genres = item.metadata.genre.split(",").map((genre) => genre.trim());
    return genres.includes("Sci-Fi");
  });
  const dispatch = useDispatch();

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 8 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 6 },
    tablet: { breakpoint: { max: 1024, min: 750 }, items: 4 },
    mobile: { breakpoint: { max: 750, min: 0 }, items: 2 },
  };

  const handleViewAllHorror = (genre) => {
    dispatch(setProductGenre(genre));
  };

  const handleBookDetails = (productId) => {
    dispatch(setProductID(productId));
  };

  return (
    <div className="SciFi">
      <div className="title">
        <h3>Sci-Fi</h3>
        <button onClick={() => handleViewAllHorror("Sci-Fi")}>VIEW ALL</button>
      </div>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={false}
        keyBoardControl={true}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {sciFiGenreProducts.map((product) => (
          <div
            className="sci-fi-card"
            key={product.id}
            onClick={() => handleBookDetails(product.id)}
          >
            <img src={product.images[0]} alt="Book Cover" />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default SciFi;
