import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./slices/products";
import metricsReducer from "./slices/metrics";
import isLoadingReducer from "./slices/isLoading";
import windowWidthReducer from "./slices/windowWidth";
import productIDReducer from "./slices/productID";
import productGenreReducer from "./slices/productGenre";
import userReducer from "./slices/user";
import cartItemsReducer from "./slices/cartItems";
import toggleCartReducer from "./slices/toggleCart";
import libraryReducer from "./slices/library";
import successMessageReducer from "./slices/successMessage";
import errorMessageReducer from "./slices/errorMessage";
import productionReducer from "./slices/production";

export const store = configureStore({
  reducer: {
    products: productsReducer,
    metrics: metricsReducer,
    productID: productIDReducer,
    productGenre: productGenreReducer,
    isLoading: isLoadingReducer,
    windowWidth: windowWidthReducer,
    user: userReducer,
    cartItems: cartItemsReducer,
    toggleCart: toggleCartReducer,
    library: libraryReducer,
    successMessage: successMessageReducer,
    errorMessage: errorMessageReducer,
    production: productionReducer,
  },
});
