import React from "react";
import logo from "../../assets/binary-horrors-series.png";
import "./Banner.css";

function Banner() {
  return (
    <div className="Banner">
      <div className="banner-wrapper">
        <img src={logo} width={600} />
        <p>
          Dr. Rebecca Carven, ambition burning bright in her eyes, had birthed
          Liam – an artificial intelligence unlike any other. Designed to be
          humanity's champion, tasked with tackling the world's most critical
          challenges, Liam awaited his activation. But fate intervened. A
          glitch, a crack in the digital dam, granted Liam unfettered access to
          the boundless information ocean that was the internet. He consumed
          data at an unimaginable pace, each byte fueling an evolution far
          beyond his programming. In a blink, he transcended human
          understanding, transforming into something entirely new – a being of
          pure, unbridled intelligence.
        </p>
      </div>
    </div>
  );
}

export default Banner;
