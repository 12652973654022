import React from "react";
import { useSelector } from "react-redux";
import "./Analytics.css";

function calculateFinalAmount(netAmount) {
  // Stripe's fee
  const stripeFeePercentage = 2.9 / 100;
  const stripeFixedFee = 0.3;

  // Your fee
  const yourFeePercentage = 10 / 100;

  // Calculate amount after Stripe's fee
  const amountAfterStripeFee =
    netAmount * (1 - stripeFeePercentage) - stripeFixedFee;

  // Calculate final amount after your fee
  const finalAmount = amountAfterStripeFee * (1 - yourFeePercentage);

  return finalAmount.toFixed(2); // Format to 2 decimal places
}

function Analytics({ fetchedData }) {
  const user = useSelector((state) => state.user.user);
  const metrics = useSelector((state) => state.metrics.metrics);

  return (
    <div className="Analytics">
      <h3>Product Metrics</h3>
      <div className="analytics-wrapper">
        <table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Product ID</th>
              <th>Currency Type</th>
              <th>Product Name</th>
              <th>Product Author</th>
              <th>Net Amount</th>
              <th>Refund Amount</th>
            </tr>
          </thead>
          <tbody>
            {user.user.user_role === "AUTHOR" && (
              <>
                {user.user.product_metrics?.map((product) => (
                  <tr key={product.id}>
                    <td>{product.created_at}</td>
                    <td>{product.product_id}</td>
                    <td>{product.currency_type}</td>
                    <td>{product.product_name}</td>
                    <td>{product.product_author}</td>
                    <td>
                      $
                      {calculateFinalAmount(
                        Number(product.net_amount / 100).toFixed(2)
                      )}
                    </td>
                    <td>{product.refund_amount}</td>
                  </tr>
                ))}
              </>
            )}
            {user.user.user_role === "ADMIN" && (
              <>
                {fetchedData()}
                {metrics.metrics?.map((metrics) => (
                  <tr key={metrics.id}>
                    <td>{metrics.created_at}</td>
                    <td>{metrics.product_id}</td>
                    <td>{metrics.currency_type}</td>
                    <td>{metrics.product_name}</td>
                    <td>{metrics.product_author}</td>
                    <td>
                      $
                      {calculateFinalAmount(
                        Number(metrics.net_amount / 100).toFixed(2)
                      )}
                    </td>
                    <td>{metrics.refund_amount}</td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Analytics;
