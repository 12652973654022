import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { MdAnalytics, MdDashboard } from "react-icons/md";
import { HiMiniUsers } from "react-icons/hi2";
import "./DashboardNavbar.css"; // Ensure you have the correct path to your CSS file

function DashboardNavbar() {
  const user = useSelector((state) => state.user.user);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Effect to handle resize event
  useEffect(() => {
    // Function to update the screen size in state
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add the event listener when the component mounts
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log(screenSize.width);

  return (
    <div className="SideNav">
      <nav>
        <ul>
          <li>
            <NavLink to="/dashboard">
              {screenSize.width > 767 ? "Overview" : <MdDashboard size={24} />}
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/analytics">
              {screenSize.width > 767 ? "Analytics" : <MdAnalytics size={24} />}
            </NavLink>
          </li>
          {user.user.user_role === "ADMIN" && (
            <li>
              <NavLink to="/dashboard/users">
                {screenSize.width > 767 ? "Users" : <HiMiniUsers size={24} />}
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default DashboardNavbar;
