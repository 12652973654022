import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  library: [],
};

export const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    setLibrary: (state, action) => {
      state.library = action.payload;
    },
  },
});

export const { setLibrary } = librarySlice.actions;

export default librarySlice.reducer;
