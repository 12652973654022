import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  successMessage: "",
};

export const successMessageSlice = createSlice({
  name: "successMessage",
  initialState,
  reducers: {
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
  },
});

export const { setSuccessMessage } = successMessageSlice.actions;

export default successMessageSlice.reducer;
