import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProducts } from "../../redux/slices/products";
import { setIsLoading } from "../../redux/slices/isLoading";

function ProductData() {
  const dispatch = useDispatch();
  const production = useSelector((state) => state.production.production);
  const testingURL = "http://127.0.0.1:5000/products";
  const productionURL = "https://lkp-api.vercel.app/products";

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(production ? productionURL : testingURL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        dispatch(setProducts(data));
        dispatch(setIsLoading(false));
      } catch (error) {
        console.error("Error fetching products:", error.message);
      }
    };

    fetchProducts();
  }, [dispatch]);

  return null; // This component doesn't render anything visible
}

export default ProductData;
