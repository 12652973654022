import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productGenre: "",
};

export const productGenreSlice = createSlice({
  name: "productGenre",
  initialState,
  reducers: {
    setProductGenre: (state, action) => {
      state.productGenre = action.payload;
    },
  },
});

export const { setProductGenre } = productGenreSlice.actions;

export default productGenreSlice.reducer;
