import React from "react";
import { useSelector } from "react-redux";
import Library from "../Library/Library";
import NewReleases from "../NewReleases/NewReleases";
import Banner from "../Banner/Banner";
import Horror from "../Horror/Horror";
import SciFi from "../SciFi/SciFi";
import Dystopian from "../Dystopian/Dystopian";
import ChildrensStories from "../ChildrensStories/ChildrensStories";
import "./Products.css";

function Products() {
  const user = useSelector((state) => state.user.user);

  return (
    <div className="Products">
      {user && <Library />}
      <NewReleases />
      <Horror />
      <Banner />
      <SciFi />
      <Dystopian />
      <ChildrensStories />
    </div>
  );
}

export default Products;
