import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy">
      <div className="privacy-policy-wrapper">
        <h3>Privacy Policy</h3>
        <p>
          Welcome to our website. Your privacy is of utmost importance to us.
          This Privacy Policy outlines how we collect, use, and protect your
          personal information when you use our services. By accessing and using
          our website, you agree to the practices described in this policy. If
          you do not agree with these terms, please do not use our services.
        </p>
        <h3>1. Collection of Personal Information</h3>
        <p>
          When you create an account and make purchases on our website, we
          collect personal details to facilitate transactions and improve your
          user experience. This includes gathering your full name, email
          address, billing address, and payment information. Your name
          personalizes interactions with our service, while your email address
          serves as a primary communication channel for order confirmations,
          account updates, and customer support. We require your billing address
          for payment verification to ensure secure and accurate transactions.
          Additionally, we collect payment details like credit card numbers to
          securely process your purchases. This data enables us to deliver
          purchased ebooks promptly and maintain a seamless user experience
          tailored to your preferences. We prioritize securing and safeguarding
          your personal information in accordance with applicable privacy laws
          and industry standards.
        </p>
        <h3>2. Use of Information</h3>
        <p>
          We utilize your personal information to ensure smooth transaction
          processing, facilitate access to purchased ebooks, and maintain
          effective communication regarding your account and transactions. This
          includes employing your details such as name, email address, and
          billing information to process payments securely and accurately.
          Additionally, we use this information to grant you access to the
          ebooks you’ve purchased, ensuring a seamless user experience.
          Communication regarding your account, such as order confirmations,
          updates, and customer service responses, is conducted through your
          provided email address. By utilizing your personal information in this
          manner, we aim to enhance your interaction with our services,
          delivering timely and relevant updates while adhering to stringent
          privacy and security standards to safeguard your information.
        </p>
        <h3>3. Automatic Data Collection</h3>
        <p>
          We automatically gather information about your device and interactions
          with our website to enhance user experience and ensure website
          functionality. This includes capturing your IP address, browser type,
          pages visited, and timestamps of visits. Your IP address helps us
          identify your geographic location and diagnose technical issues, while
          browser type and pages visited provide insights into your browsing
          preferences and behavior on our site. Timestamps allow us to analyze
          peak usage times and improve website performance accordingly. This
          data enables us to personalize your browsing experience, optimize our
          website’s layout, and provide relevant content. We handle this
          information responsibly, using it solely for improving our services
          and ensuring your interactions with our website are seamless and
          efficient.
        </p>
        <h3>4. Security Measures</h3>
        <p>
          We employ reasonable security measures to safeguard your personal
          information against unauthorized access, misuse, or disclosure. These
          measures include encryption protocols, secure server infrastructure,
          and regular security audits. However, it’s important to note that
          while we strive to protect your data, no method of transmission over
          the internet or electronic storage is entirely secure. Therefore, we
          cannot guarantee absolute security of your information. We
          continuously monitor and update our security practices to mitigate
          risks and adhere to industry standards. Your privacy and data security
          are paramount, and we remain committed to protecting your personal
          information to the best of our abilities. If you have any concerns
          about the security of your data, please contact us for further
          information.
        </p>
        <h3>5. Third-Party Service Providers</h3>
        <p>
          We collaborate with trusted third-party service providers to support
          our website operations, business activities, and customer service
          efforts. These providers may have access to your personal information
          to assist in tasks such as payment processing, order fulfillment,
          technical support, and marketing services. However, we ensure that
          these third parties are contractually obligated to uphold the
          confidentiality and security of your information. They are prohibited
          from using your personal data for any purpose other than fulfilling
          their designated responsibilities to us. We carefully select and
          monitor our third-party partners to maintain the integrity of your
          privacy and security standards. If you have any inquiries or wish to
          learn more about our third-party practices, please don’t hesitate to
          contact us.
        </p>
        <h3>6. Marketing Communications</h3>
        <p>
          With your consent, we send promotional emails informing you about new
          ebooks, special offers, or updates regarding our products and
          services. These communications aim to provide you with valuable
          information and exclusive offers that may interest you. You have the
          option to opt-out of receiving these emails at any time by following
          the unsubscribe instructions provided in the emails. Your preferences
          regarding marketing communications are respected, and we strive to
          ensure that our promotional efforts align with your interests and
          preferences. If you choose to opt-out, you will no longer receive
          promotional emails from us, although transactional emails related to
          your account and purchases will continue as necessary. We value your
          privacy and are committed to providing you with control over your
          communication preferences. Please feel free to contact us if you have
          any questions or require further assistance regarding our marketing
          practices.
        </p>
        <h3>7. Data Retention</h3>
        <p>
          We keep your personal information for the duration necessary to
          achieve the objectives outlined in this Privacy Policy unless extended
          retention is mandated or permissible under applicable law. This
          includes maintaining records to fulfill your ebook orders, provide
          customer support, and comply with legal obligations. We assess the
          retention period based on the nature of the information, its intended
          use, and legal requirements. If no longer needed for its initial
          purpose, we securely delete or anonymize your personal data. We uphold
          strict confidentiality and security standards during the retention and
          disposal processes to protect your information from unauthorized
          access or disclosure. Should you have any queries about our data
          retention practices or wish to request the deletion of your
          information, please contact us using the details provided in this
          Privacy Policy.
        </p>
        <h3>8. Children’s Privacy</h3>
        <p>
          Our services are designed for individuals aged 13 and above and are
          not intended for children under the age of 13. We do not knowingly
          collect personal information from children under 13 years old. If we
          discover that personal information of a child under 13 has been
          inadvertently collected, we promptly take steps to delete it from our
          records. We encourage parents and guardians to monitor their
          children’s online activities and use parental controls to ensure
          compliance with age restrictions and protect children’s privacy
          online. Our commitment to children’s privacy aligns with applicable
          laws and regulations, and we continuously review and update our
          practices to safeguard the personal information of all users,
          including minors. For further information or to report any concerns
          regarding children’s privacy, please contact us using the information
          provided in this Privacy Policy.
        </p>
        <h3>9. User Rights</h3>
        <p>
          Users have the right to access their personal data held by us, request
          corrections to inaccurate or incomplete data, and request the deletion
          of their data under certain conditions. These conditions include the
          data no longer being necessary for the purposes collected, withdrawal
          of consent, and objections to processing. Users can also request
          information about data processing purposes, data categories,
          recipients, storage periods, and their rights regarding data
          rectification, erasure, restriction, objection, and lodging complaints
          with supervisory authorities. Additionally, users have the right to
          know about the existence of autosxmated decision-making, including
          profiling, and to receive meaningful information about the logic
          involved and the potential consequences of such processing.
        </p>
        <h3>10. Changes to the Privacy Policy</h3>
        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time. Changes to the Privacy Policy will be effective immediately upon
          posting the updated version on this page. It is important that you
          review this Privacy Policy periodically to stay informed about how we
          are protecting your information and to understand any changes or
          updates. By continuing to use our services after the changes are made,
          you agree to be bound by the revised Privacy Policy. If you do not
          agree with the terms of this Privacy Policy or any updates, please
          discontinue use of our services.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
