import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWindowWidth } from "../../redux/slices/windowWidth";
import { setCartItems } from "../../redux/slices/cartItems";
import { setProductID } from "../../redux/slices/productID";
import { setSuccessMessage } from "../../redux/slices/successMessage";
import Hero from "../../components/Hero/Hero";
import Products from "../../components/Products/Products";
import ProductByID from "../../components/ProductByID/ProductByID";
import ProductByGenre from "../../components/ProductByGenre/ProductByGenre";
import { setErrorMessage } from "../../redux/slices/errorMessage";

function HomePage() {
  const products = useSelector((state) => state.products.products);
  const productID = useSelector((state) => state.productID.productID);
  const cartItems = useSelector((state) => state.cartItems.cartItems);
  const productGenre = useSelector((state) => state.productGenre.productGenre);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      dispatch(setWindowWidth(window.innerWidth));
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const addToCart = (product) => {
    // Check if the product already exists in cartItems
    const isProductInCart = cartItems.some((item) => item.id === product.id);

    if (!isProductInCart) {
      // If not in cartItems, update localStorage and dispatch
      const updatedCartItems = [...cartItems, product];
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
      dispatch(setCartItems(updatedCartItems));
      dispatch(setProductID(null));
      dispatch(setSuccessMessage("Product added to cart!"));
    } else {
      // Optionally, provide feedback that the product is already in cart
      dispatch(setErrorMessage(`Product is already in the cart.`));
    }
  };

  const featuredProducts = products.filter(
    (product) =>
      product.metadata && product.metadata.featured_product === "true"
  );

  return (
    <div className="HomePage">
      <Hero featuredProducts={featuredProducts} addToCart={addToCart} />
      <Products />
      {productID && <ProductByID addToCart={addToCart} />}
      {productGenre && <ProductByGenre addToCart={addToCart} />}
    </div>
  );
}

export default HomePage;
